// title: Home
// frameCardID: 541faaf0-4832-47b5-bb92-881dd70d6309

import * as styles from './index.module.scss'

import React from 'react'
import { Link, graphql, PageProps } from 'gatsby'
import { compact } from 'lodash'

import Layout, { FrameCard } from 'components/layout/Layout'
import PageMetaData from 'src/components/layout/PageMetaData'

import PodcastBlock from 'components/article-elements/PodcastBlock'
import MinimalArtCredit from 'components/home/MinimalArtCredit'
import BannerRow from 'components/home/BannerRow'

import cubeMapBannerImage from 'components/home/cube-map-banner.png'
import edhMapBannerImage from 'components/home/edh-map-banner.png'

interface Props extends PageProps<Queries.HomePageQuery> {
  pageContext: any
}

const HomePage: React.FC<Props> = (props) => {
  const article = props.data.article.articles[0]
  const podcast = props.data.podcast.podcasts[0]

  return (
    <Layout
      {...props.pageContext}
      accentColor="#8C47B4"
      includeNewsletterSignup
    >
      <div className={styles.container}>
        {article?.frontmatter != null && article.fields != null && (
          <Link key={article.id} to={article.fields.slug ?? ''}>
            <article className={styles.article}>
              <div className={styles.articleAccent}>
                <div className={styles.articleAccentCredit}>
                  {article.fields.frameCard && (
                    <MinimalArtCredit
                      card={article.fields.frameCard as FrameCard}
                    />
                  )}
                </div>
                <img
                  className={styles.articleAccentImage}
                  src={article.fields.frameCard?.imageURL ?? ''}
                  alt=""
                  role="presentation"
                />
              </div>
              <div className={styles.articleInfo}>
                <div className={styles.articleInfoLabel}>Feature Article</div>
                <h3 className={styles.articleInfoSeries}>
                  {article.frontmatter.series}
                </h3>
                <h2 className={styles.articleInfoTitle}>
                  {article.frontmatter.title}
                </h2>
                {article.frontmatter.authors && (
                  <div className={styles.articleInfoAuthor}>
                    Written by{' '}
                    {compact(article.frontmatter.authors)
                      .map((author) => author.name)
                      .join(' & ')}
                  </div>
                )}
                <div className={styles.articleInfoExcerpt}>
                  {article.frontmatter.excerpt}
                </div>
                <div className={styles.articleInfoDate}>
                  {article.frontmatter.date}
                </div>
              </div>
            </article>
          </Link>
        )}
        <div className={styles.row}>
          {podcast.frontmatter != null && (
            <PodcastBlock key={podcast.id}>
              <Link to={`/podcast/${podcast.frontmatter.episodeSlug}/`}>
                <section className={styles.podcast}>
                  <div className={styles.podcastTitle}>
                    {podcast.frontmatter.title}
                  </div>
                  <div className={styles.podcastExcerpt}>
                    {podcast.frontmatter.excerpt}
                  </div>
                  {podcast.frontmatter.episodeNumber ? (
                    <div className={styles.podcastNumber}>
                      Lucky Paper Radio Episode{' '}
                      {podcast.frontmatter.episodeNumber}
                    </div>
                  ) : (
                    <div className={styles.podcastNumber}>
                      Lucky Paper Radio Bonus Episode
                    </div>
                  )}
                </section>
              </Link>
            </PodcastBlock>
          )}

          <div className={styles.video}>
            <iframe
              className={styles.videoIFrame}
              src="https://www.youtube.com/embed/videoseries?list=PLNL3vXDQDlld7m99rIqQHc8_gFlPZE8jp"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <BannerRow
          to="/resources/cube-map/"
          backgroundImage={cubeMapBannerImage}
          title="Explore the Cube Universe"
          subtitle="Discover just how diverse the Cube format can be, themes you never expected, and where your own cube fits."
        />
        <BannerRow
          to="/resources/commander-map/"
          backgroundImage={edhMapBannerImage}
          title="Commander Map"
          subtitle="Explore trends and patterns in an interactive visualization of over two and a half million Commander decks organized by the cards they contain."
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query HomePage {
    article: allMdx(
      filter: { fields: { type: { eq: "articles" } } }
      sort: { frontmatter: { date: DESC } }
      limit: 1
    ) {
      articles: nodes {
        id
        frontmatter {
          title
          series
          date(formatString: "MMMM Do, YYYY")
          excerpt
          authors {
            name
          }
        }
        fields {
          slug
          frameCard {
            id
            name
            set
            artist
            imageURL
          }
        }
      }
    }
    podcast: allMdx(
      filter: { fields: { type: { eq: "podcast-notes" } } }
      sort: { frontmatter: { episodeOrder: DESC } }
      limit: 1
    ) {
      podcasts: nodes {
        id
        frontmatter {
          title
          date(formatString: "MMMM Do, YYYY")
          excerpt
          episodeSlug
          episodeNumber
        }
      }
    }
  }
`

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'Lucky Paper',
        description:
          'A love letter to Magic: the Gathering. Articles about Cube, Battle Box, and other "sandbox" Magic formats.',
        ogImageURL: 'https://luckypaper.co/lucky-paper.jpg',
      }}
    />
  )
}

export default HomePage
