import * as styles from './MinimalArtCredit.module.scss'

import React from 'react'

import { useCardHover } from 'components/cards/CardHover'

interface Props {
  card: {
    name: string
    set: string
    artist: string
  }
}

const MinimalArtCreditTag: React.FC<Props> = (props) => {
  const { name, artist, set } = props.card

  const { hoverProps, portal } = useCardHover(name, set)

  return (
    <div className={styles.container} {...hoverProps}>
      <>
        {portal}
        <div title={`${name} — ${artist}`}>ⓘ</div>
      </>
    </div>
  )
}

export default MinimalArtCreditTag
