import * as styles from './PodcastBlock.module.scss'

import React from 'react'

interface Props {
  children: React.ReactNode
}

const PodcastBlock: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>▶&#xFE0E;</div>
      {props.children}
    </div>
  )
}

export default PodcastBlock
